import React from "react";
import { Row, Col, Space } from "antd";
import "./Home.less";
import { Container } from "../../components/Container/Container";
import { AppButton } from "../../components/AppButton/AppButton";
import ConnectWallet from "../../components/AppButton/ConnectWallet";

export const Home = () => {
  return (
    <section id="home" className="home">
      <div className="menu-header">
        <div className="flex-menu">
          <a href="#">
            <img
              className="app-header__logo"
              src="/assets/images/logo.svg"
              alt="logo"
            />
          </a>
          {/* <ConnectWallet /> */}
        </div>
      </div>
      <Container className="home__container">
        <Row className="flex-home">
          <Col xs={24} lg={12} className="home__intro">
            <h1 className="intro__heading">
              A go-to platform for
              <br />
              the evolution of <span> NFT Projects</span>
            </h1>
            <p className="intro__sub-heading">
              Powerful Launchpad and NFT Marketplace, the next-level <br /> NFT
              staking.
            </p>
            <Space size={"large"} className="intro__button-group">
              <AppButton className="launch_app">
                <a target="_blank" rel="noreferrer" href="http://app.dc8.one/">
                  Launch app
                </a>
              </AppButton>
              <AppButton className="read-doc">
                <a
                  target="_blank"
                  href="https://app.gitbook.com/o/evxfXL89ycn0DbbrBZ1U/s/hqOzAnVTogzNJHZTG4Rx/"
                  rel="noreferrer"
                >
                  Read docs
                </a>
              </AppButton>
            </Space>
          </Col>
          <Col xs={24} lg={12} className="home__img-wrapper">
            <img
              className="home__img"
              src={"/assets/images/dc8-banner.png"}
              alt={"intro"}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
