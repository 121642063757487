import styled from "styled-components";
import {
  Col,
  Divider,
  Modal,
  Space,
  Typography,
} from "antd";

const { Text } = Typography;

export const StyledTextTitleAsk = styled(Text)`
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  color: #73d98f;
  text-transform: uppercase;
`;

export const StyledDivider = styled(Divider)`
  border: 1px solid #3e446f;
  margin: 0;
`;

export const StyledTextDesAsk = styled(Text)`
  font-weight: 400;
  font-size: 14px;
`;

export const StyledInputTicketOwner = styled(Space)`
  width: 100%;
  border: 1px solid #9197ba;
  border-radius: 50px;
  background: transparent;
  min-height: 44px;
`;

export const StyledProjectIncubatorCol = styled(Col)`
  width: 50%;

  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 992px) {
    width: 25%;
  }
`;
export const StyledModalSelectNft = styled(Modal)`
  .ant-modal-close-x {
    font-size: 25px;
    color: #ffffff;
    font-weight: 700;
  }
  
  width: 100%;

  @media (min-width: 992px) {
    min-width: 760px;
  }
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #1b214f;
    border-radius: 12px;
  }
`;
