import { Button, Image, Row, Typography } from "antd";
import styled from "styled-components";

const { Text } = Typography;

export const StyledWrapDaoInfoComponent = styled(Row)`
  margin-bottom: 5rem;
  margin-top: 5rem;
  @media (min-width: 992px) {
    margin-bottom: 0;
  margin-top: 0;
  }
`;