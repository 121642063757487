import { Col, Row } from "antd";
import React from "react";
import { Container } from "../../components/Container/Container";
import "./Partner.less";

const Partner = () => {
  return (
    <section section id="partner" className="partner">
      <Container>
        <Row gutter={[24, 24]} justify="center">
          <h3 className="heading-partner">Partner</h3>
        </Row>
        <Row gutter={[24, 24]} justify="center">
          <div
            style={{ display: "flex", justifyContent: "space-around" }}
            className="box-partner"
          >
            <Col>
              <a href="https://linktr.ee/bitfinitynet" target="_blank">
                <img
                  className="avatar_box"
                  src="/assets/icons/partner.png"
                  alt=""
                  style={{ maxWidth: "130px" }}
                />
              </a>
            </Col>
            <Col
              style={{ display: "flex", flexDirection: "column" }}
              className="box-body"
            >
              <Row style={{ width: "100%" }}>
                <a
                  className="title_box"
                  target="_blank"
                  rel="noreferrer"
                  href="https://linktr.ee/bitfinitynet"
                >
                  Bitfinity Network
                </a>
              </Row>
              <Row style={{ width: "100%" }}>
                <p className="content_box">
                  The Bitfinity Network is building next generation web3 architecture.
                </p>
              </Row>
            </Col>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Partner;
