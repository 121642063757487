import React, { useState } from "react";
import { Col, Image, Row, Space, Typography } from "antd";
import {
  StyledModalSelectNft,
  StyledTextDesAsk,
  StyledTextTitleAsk,
} from "./style";
import CardDao from "./Card";

const { Text } = Typography;

const ModalDaoBenefit = ({
  isOpenModalDaoBenefit,
  setIsOpenModalDaoBenefit,
}) => {
  //   const [isOpenModalDaoBenefit, setIsOpenModalDaoBenefit] = useState(false);

  const handleCancelModalDaoBenefit = () => {
    setIsOpenModalDaoBenefit(false);
  };

  //   const handleOpenModalDaoBenefit = () => {
  //     setIsOpenModalDaoBenefit(true);
  //   };

  return (
    <StyledModalSelectNft
      centered
      open={isOpenModalDaoBenefit}
      onCancel={handleCancelModalDaoBenefit}
      footer={null}
    >
      <Row style={{ width: "100%" }}>
        <Image
          // style={{ width: "100%" }}
          preview={false}
          src="/assets/images/image-intro-benefit.svg"
        />
      </Row>
      <Row
        style={{
          width: "100%",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <StyledTextTitleAsk>HYBRID DOLLS HOLDER’S BENEFIT</StyledTextTitleAsk>
      </Row>
      <Row
        style={{
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Text style={{ fontSize: "20px", fontWeight: 400, color: "#CCD3FF" }}>
          DC8: Treasury
        </Text>
      </Row>
      <Row
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        gutter={20}
      >
        <Col
          style={{
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          xl={10}
          sm={24}
        >
          <Row gutter={10}>
            <Col span={12}>
              <CardDao
                ratio={75}
                des="Profit from
NFT Launchpad"
              />
            </Col>
            <Col span={12}>
              <CardDao
                ratio={75}
                des="Profit from
NFT Marketplace"
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <CardDao
                ratio={12}
                des="Staking pool:
APY per year"
              />
            </Col>
            <Col span={12}>
              <CardDao
                ratio={4}
                des="Adminstrative pool:
token profits"
              />
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Space
              style={{
                borderRadius: "12px",
                background: "#1A2E7C",
                width: "100%",
                height: "10vh",
                padding: "10px",
              }}
            >
              <Row style={{ justifyContent: "center", alignItems: "center" }}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#73D98F",
                  }}
                >
                  Launchpad Rewards: Whitelist, Discount, Bulk buy option, ETH,
                  ICP
                </Text>
              </Row>
            </Space>
          </Row>
        </Col>
        <Col xl={14} sm={24} >
          <Row style={{ marginBottom: "10px" }}>
            <Text
              style={{ fontSize: "14px", fontWeight: 400, color: "#CCD3FF" }}
            >
              When becoming Hybrid Dolls holders, you will receive profits from
              all functions of the DC8 Fundraising Platfrom following details:
            </Text>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Text
              style={{ fontSize: "14px", fontWeight: 400, color: "#CCD3FF" }}
            >
              DC8 Treasury:
              <br />
              - 75% Profit from NFT Launchpad
              <br />- 75% Profit from NFT Marketplace
            </Text>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Text
              style={{ fontSize: "14px", fontWeight: 400, color: "#CCD3FF" }}
            >
              Launchpad Rewards: Whitelist, Discount, Bulk buy option, ETH, ICP
            </Text>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Text
              style={{ fontSize: "14px", fontWeight: 400, color: "#CCD3FF" }}
            >
              Staking Pool: Staking Pool will pay Long-term Stakeholders Token
              profits at a fixed APY 12% per year. Based on the Staking Profit
              formula, Staking Pool will equally distribute 1% of profits per
              month to Long-Term Stakeholders.
            </Text>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Text
              style={{ fontSize: "14px", fontWeight: 400, color: "#CCD3FF" }}
            >
              Administrative Pool: The higher the number of “Tickets” in the
              Administrative Pool, the more Token profits users will receive.
              New NFT projects must be approved at the Project Incubator before
              Administrative Pool releases 4% Token profits to all voters based
              on the Voting Profit Calculation Formula.
            </Text>
          </Row>
        </Col>
      </Row>
    </StyledModalSelectNft>
  );
};

export default ModalDaoBenefit;
