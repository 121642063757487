import Layout, { Content } from "antd/lib/layout/layout";
import { Home } from "./containers/Home/Home";
import { About } from "./containers/About/About";
import { BusinessPlan } from "./containers/BusinessPlan/BusinessPlan";
import "./App.less";
import Partner from "./containers/Partner/Partner";
import { Roadmap } from "./containers/Roadmap/Roadmap";
import DaoInfoBenefit from "./containers/DaoInfoBenefit/DaoInfoBenefit";
import { Row } from "antd";
import { StyledWrapDaoInfoComponent } from "./style";

function App() {
  return (
    <Layout>
      {/* <AppHeader /> */}
      <Content>
        <Home />
        <StyledWrapDaoInfoComponent>
          <DaoInfoBenefit />
        </StyledWrapDaoInfoComponent>
        <Roadmap />
        <About />
        <Partner />
        <BusinessPlan />
      </Content>
    </Layout>
  );
}

export default App;
