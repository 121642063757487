import React, { useState } from "react";
import { Col, Row } from "antd";
import { Container } from "../../components/Container/Container";
import styled from "styled-components";
import "./Roadmap.less";
import { isMobile } from "mobile-device-detect";
const StepsStyled = styled.div`
  &::before {
    content: "";
    position: absolute;
    width: ${(props) => props.w || "35%"};
    background: #fff;
    border: 2px solid #2f5dff;
    top: 0;
    left: 0;
    height: 100%;
  }
`;
export const Roadmap = () => {
  const [wActive, setWActive] = useState("35%");
  const [currentStep, setCurrentStep] = useState(1);
  return (
    <section id="Roadmap" className="roadmap-plan">
      <Container className="roadmap-plan__container">
        <Row gutter={64} style={{ paddingTop: "20px" }}>
          <Col sm={24} md={8} style={{ width: "100%" }}>
            <h1 className="roadmap-plan__title">Roadmap</h1>
            <div className="roadmap__left">
              <img
                src="/assets/images/dc8-bg-roadmap.png"
                alt="bg"
                className="bg"
              />
              <img
                src="/assets/images/dc8-roadmap.png"
                alt="banner"
                className="banner_roadmap"
              />
              {/* <img className="roadmap-plan__img"
                src={"/assets/images/roadmap.png"} alt={"roadmap"} /> */}
            </div>
          </Col>
          <Col sm={24} md={16} style={{ width: "100%" }}>
            <StepsStyled className="step-process" w={wActive}>
              {isMobile ? (
                <div
                  className="step-item"
                  data-text={currentStep === 3 ? "Brainstorming" : ""}
                  onClick={() => {
                    setWActive("5%");
                    setCurrentStep(3);
                  }}
                  style={
                    currentStep === 3
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              ) : (
                <div
                  className="step-item"
                  data-text={"Brainstorming"}
                  onClick={() => {
                    setWActive("5%");
                    setCurrentStep(3);
                  }}
                  style={
                    currentStep === 3
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              )}
              {isMobile ? (
                <div
                  className={"step-item"}
                  data-text={currentStep === 1 ? "Planning" : ""}
                  onClick={() => {
                    setWActive("35%");
                    setCurrentStep(1);
                  }}
                  style={
                    currentStep === 1
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              ) : (
                <div
                  className={"step-item"}
                  data-text={"Planning"}
                  onClick={() => {
                    setWActive("35%");
                    setCurrentStep(1);
                  }}
                  style={
                    currentStep === 1
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              )}
              {isMobile ? (
                <div
                  className="step-item"
                  data-text={currentStep === 2 ? "In progress" : ""}
                  onClick={() => {
                    setWActive("65%");
                    setCurrentStep(2);
                  }}
                  style={
                    currentStep === 2
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              ) : (
                <div
                  className="step-item"
                  data-text={"In progress"}
                  onClick={() => {
                    setWActive("65%");
                    setCurrentStep(2);
                  }}
                  style={
                    currentStep === 2
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              )}
              {isMobile ? (
                <div
                  className="step-item"
                  data-text={"Done"}
                  onClick={() => {
                    setWActive("100%");
                    setCurrentStep(4);
                  }}
                  style={
                    currentStep === 4
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              ) : (
                <div
                  className="step-item"
                  data-text={"Done"}
                  onClick={() => {
                    setWActive("100%");
                    setCurrentStep(4);
                  }}
                  style={
                    currentStep === 4
                      ? {
                          width: "20px",
                          height: "20px",
                          border: "1px solid #B8CCFF",
                        }
                      : {
                          width: "12px",
                          height: "12px",
                          border: "1px solid #1A2E7C",
                        }
                  }
                />
              )}
            </StepsStyled>
            {currentStep === 1 && (
              <div className="card-roadmap">
                <div className="content-text">
                  <div>
                    <img src={"/assets/icons/roadmap-right.svg"} />
                    <span className="mounth">Q3 2023</span>
                  </div>
                  <div className="step">
                    <i>Step 2 - DC8 Marketplace goes live</i>
                  </div>
                  <div className="step">
                    Launch marketplace, allowing holders of Hybrid Dolls NFTs
                    issued by ICE Studio to conduct transactions with each
                    other. Partner up with other projects on multichain to list
                    their NFT collections on the marketplace.
                  </div>
                </div>
                <div className="content-text">
                  <div>
                    <img src={"/assets/icons/roadmap-right.svg"} />
                    <span className="mounth">Q4 2023</span>
                  </div>
                  <div className="step">
                    <i>Step 3 - Staking NFTs</i>
                  </div>
                  <div className="step">
                    Allow users to stake Hybrid Dolls NFTs on testnet server.
                    Solicit feedback from community, enhance efficiency, then
                    launch the official server. Stakeholders can vote for the
                    projects they think worth being fundraised in the future.
                    Develop DC8 Marketplace as a DAO based on the decisions made
                    by stakeholders.
                  </div>
                </div>
                <div className="content-text">
                  <div>
                    <img src={"/assets/icons/roadmap-right.svg"} />
                    <span className="mounth">Q1 2024</span>
                  </div>
                  <div className="step">
                    <i>Step 4 - Project Incubator goes live</i>
                  </div>
                  <div className="step">
                    NFTs projects can request to be listed on DC8 marketplace.
                    However, whether or not a project is successfully listed on
                    the market place is at stakeholders’ discretion. Each Hybrid
                    Dolls NFTs has a particular rarity index, which results in
                    the difference in "Tickets". Stakeholders enjoy advantages
                    such as presale buys, airdrop, etc..
                  </div>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="card-roadmap">
                <div className="content-text">
                  <div>
                    <img src={"/assets/icons/roadmap-right.svg"} />
                    <span className="mounth">Q2 2023</span>
                  </div>
                  <div className="step">
                    <i>Step 1 - Explore the world</i>
                  </div>
                  <div className="step">
                    Produce 5000 Hybrid Dolls NFTs which will be used to
                    administer future marketplace. Distribute whitelist slots
                    and launch primary sale shortly. Store 25 percent of primary
                    sale into DC8 treasury.
                  </div>
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="card-roadmap">
                <div className="content-text">
                  <div>
                    <img src={"/assets/icons/roadmap-right.svg"} />
                    <span className="mounth">
                      Decentralized NFTs Marketplace living on Multichain
                    </span>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 4 && (
              <div className="card-roadmap">
                <div className="content-text">
                  <div>
                    <span className="mounth">Coming soon...</span>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
