import { Button, Image, Row, Typography } from "antd";
import styled from "styled-components";

const { Text } = Typography;

export const StyledRowWrapDaoInfoBenefit = styled(Row)`
  width: 100%;
  justify-content: center;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    margin-bottom: 15rem;
  }
`;

export const StyledWrapImageBgBehindDesDaoFirst = styled(Image)`
  width: 100%;
  height: 100%;
  @media (min-width: 992px) {
  }
`;

export const StyledRowContainBgBtnBenefit = styled(Row)`
  width: 100%;
  justify-content: center;
  position: relative;
  margin-top: 8rem;

  @media (min-width: 992px) {
    margin-top: -2rem;
  }
`;

export const StyledTextTitle = styled(Text)`
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
  @media (min-width: 992px) {
    font-size: 36px;
  }
`;

export const StyledRowContainDesSecondBenefitDao = styled(Row)`
  width: 90%;
  min-height: 350px;
  margin: 0 auto;
  background-image: url("/assets/images/bg-behind-des-dao.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 35px 0 0 0;
  justify-content: center;
  align-items: center;
  @media (min-width: 992px) {
    width: 40%;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  bottom: 60%;
  line-height: 140%;
  font-weight: 700;
  border-radius: 50px;
  box-sizing: border-box;
  display: flex;
  background: linear-gradient(
    263.75deg,
    #bd43ff -6.67%,
    #5383ff 28.31%,
    #3ddc7e 82.88%
  ) !important;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 36px;
  font-size: 14px;
  color: #ffffff !important;
  border: none;
  transition: all 0.15s ease;
  gap: 5px;
  :hover {
    box-shadow: 0 0 10px 0px #73d98f;
  }
  min-width: 150px;

  @media (min-width: 992px) {
    min-width: 300px;
    min-height: 44px;
  }
`;
