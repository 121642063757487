import React from 'react'
import { Col, Row } from 'antd';
import { Container } from '../../components/Container/Container';
import { AppButton } from '../../components/AppButton/AppButton';

import img from "../../assets/business-plan/business-img.png";
import "./BusinessPlan.less"

export const BusinessPlan = () => {
  return (
    <section id="BusinessPlan" className="business-plan">
      <Container className="business-plan__container">
        <Row gutter={64}>
          <Col sm={24} md={12} style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <div>
              <h3 className='business-plan__text'>
                About us
              </h3>
              <h1 className="business-plan__title">
                ICE Studio
              </h1>
              <p className="business-plan__desc">
                ICE Studio is a young generation having confidence in the development of Blockchain. Our community is built upon active operation and solid development on top social media sites.
              </p>
              <p className="business-plan__desc">
                Meta8, an ecosystem created by ICE Studio, is composed of experienced developers and marketers who aim to form a well-established ecosystem where enthusiasts can reap profits on Blockchain.
              </p>
            </div>

          </Col>
          <Col sm={24} md={12}>
            <img className="business-plan__img" src={"/assets/images/ice-studio.png"} alt={img} />
          </Col>

        </Row>
      </Container>
      <div style={{ position: "absolute", left: "50%", bottom: '20px', transform: "translateX(-50%)" }}>
        <div style={{
          color: "#CCD3FF",
          fontSize: "16px"
        }}>
          Powered by ICE Studio
        </div>
      </div>
    </section>
  )
}
