import React from 'react';
import { Col, Row } from 'antd';
import { createFromIconfontCN, SettingOutlined, HeartOutlined } from '@ant-design/icons';

import { Container } from "../../components/Container/Container";
import { AboutCard } from './AboutCard/AboutCard';

import "./About.less"

export const About = () => {
  const description = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab dolores ea fugiat nesciunt quisquam.'
  const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2229651_awfgn4o1jo8.js'
  });

  return (
    <section id="about" className="about">
      <Container>
        <Row gutter={[24, 24]} justify="center">
          <h3 className='heading-about'>DC8 is a
            <span> powerful NFT marketplace </span>
            set to accelerate growth of NFTs across multichain.</h3>
        </Row>
        <Row gutter={[24, 24]} justify="center">
          <Col xs={24} sm={20} md={12} lg={8}>
            <AboutCard
              classNameCustom="card_dark"
              icon={<img src={"/assets/icons/staking.svg"} alt="" />}
              title={"NFTs Marketplace"}
              description={<p>Trade NFTs <br />faster and easier at lower fees</p>}
              contentBtn={"Go to Marketplace"}
              link={"https://app.dc8.one/marketplace "}
            />
          </Col>
          <Col xs={24} sm={20} md={12} lg={8}>
            <AboutCard
              classNameCustom="card_dark"
              icon={<img src={"/assets/icons/about_nft.svg"} alt="" />}
              title={"NFTs Staking"}
              description={<p>Earn rewards <br />by staking for administration power</p>}
              contentBtn={"Go to NFT Staking "}
              link="https://app.dc8.one/staking "
            />
          </Col>
          <Col xs={24} sm={20} md={12} lg={8}>
            <AboutCard
              classNameCustom="card_dark"
              icon={<img src={"/assets/icons/launchpad.svg"} alt="" />}
              title={"Launchpad"}
              description={<p>Get published <br />on launchpad through voting</p>}
              contentBtn={"Go to Launchpad"}
              link={"https://app.dc8.one/staking "}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
