import { Col, Row, Space, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const CardDao = ({ ratio, des }) => {
  return (
    <Space
      style={{
        borderRadius: "12px",
        background: "#1A2E7C",
        width: "100%",
        height: "10vh",
      }}
    >
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          span={6}
        >
          <Text
            style={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#73D98F",
            }}
          >
            {ratio}%
          </Text>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          span={16}
        >
          <Text
            style={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#CCD3FF",
            }}
          >
            {des}
          </Text>
        </Col>
      </Row>
    </Space>
  );
};

export default CardDao;
