import { Image, Row, Typography } from "antd";
import React, { useState } from "react";
import ModalDaoBenefit from "./ModalDaoBenefit/ModalDaoBenefit";
import {
  StyledButton,
  StyledRowContainBgBtnBenefit,
  StyledRowContainDesSecondBenefitDao,
  StyledRowWrapDaoInfoBenefit,
  StyledTextTitle,
  StyledWrapImageBgBehindDesDaoFirst,
} from "./style";

const { Text } = Typography;

const DaoInfoBenefit = () => {
  const [isOpenModalDaoBenefit, setIsOpenModalDaoBenefit] = useState(false);

  const handleOpenModalDaoBenefit = () => {
    setIsOpenModalDaoBenefit(true);
  };

  return (
    <>
      <ModalDaoBenefit
        isOpenModalDaoBenefit={isOpenModalDaoBenefit}
        setIsOpenModalDaoBenefit={setIsOpenModalDaoBenefit}
      />
      <StyledRowWrapDaoInfoBenefit>
        <StyledRowContainDesSecondBenefitDao style={{ position: "relative" }}>
          <Image
            // style={{ position: "absolute", marginTop: "1rem" }}
            preview={false}
            src="/assets/images/bg-behind-des-dao-first.svg"
          />
          <Row style={{ width: "80%", justifyContent: "center", position: "absolute" }}>
            <StyledTextTitle>Hybrid Dolls holder's benefit</StyledTextTitle>
            <br />
            <br />
            <Text
              style={{ fontSize: "16px", fontWeight: 400, color: "#FFFFFF" }}
            >
              DC8 provides a mechanism whereby “Marketplace” and “Launchpad”
              will continuously contribute 75% of profits every quarter from
              both “Secondary Sale Fee” and “Fundraising Fee” to DC8 Treasury on
              multichain.
            </Text>
            <br />
            <Text
              style={{ fontSize: "16px", fontWeight: 400, color: "#FFFFFF" }}
            >
              DC8 Treasury, transparently stored on the Ethereum blockchain,
              will be divided into two equal parts of 50% each. The first 50
              percent is to fund “Staking Pool”, which pays Token profits to DC8
              stakeholders. The remaining 50 percent is to fund “Administrative
              Pool”, a profit pool that users can vote to earn.
            </Text>
          </Row>
        </StyledRowContainDesSecondBenefitDao>
        <StyledRowContainBgBtnBenefit>
          {/* <Row style={{ width: "100%", justifyContent: "center", position: "relative", }}> */}
          <StyledWrapImageBgBehindDesDaoFirst
            preview={false}
            src="/assets/images/bg-btn-benefit-dao.svg"
          />
          <StyledButton onClick={handleOpenModalDaoBenefit}>
            DC8 Ecosystem
          </StyledButton>
          {/* </Row> */}
        </StyledRowContainBgBtnBenefit>
      </StyledRowWrapDaoInfoBenefit>
    </>
  );
};

export default DaoInfoBenefit;
