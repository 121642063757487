import React from 'react';
import { Card } from 'antd';
import { AppIcon } from '../../../components/AppIcon/AppIcon';
import "./AboutCard.less"
import { AppButton } from '../../../components/AppButton/AppButton';

export const AboutCard = (props) => {
  const { Meta } = Card;
  const { icon, title, description, classNameCustom, contentBtn, link } = props;

  return (
    <Card
      hoverable
      className={"about__card " + classNameCustom}
      cover={
        <AppIcon
          icon={icon}
          className="about__card-icon"
        />
      }
    >
      <Meta title={title} description={description} />

      <AppButton className="launch_app">
        <a
          target="_blank"
          rel="noreferrer"
          href={link}>
          {contentBtn}
        </a>
      </AppButton>
    </Card>
  )
}
